import { useMutation } from '@apollo/client';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import CollapsibleContainer from '@oneflare/flarekit/lib/components/CollapsibleContainer';
import {
  ChangeEvent,
  FC,
  memo,
  useState
} from 'react';

import { CREATE_FEEDBACK_REPLY } from 'mutations/shared/businessProfile';
import { useHandleMutationAlert } from 'shared/utils/hooks';

import { LoaderWrapperStyled, LoaderStyled } from '../../styled/Reviews';

import { ReplyToReviewWrapperStyled, TextAreaStyled, ButtonStyled } from './styled/ReplyToReview';

type Props = {
  feedbackId: number;
  index: number;
  origin: string;
  updateReply: (index: number) => void;
};

const ReplyToReview: FC<Props> = ({
  feedbackId,
  index,
  origin,
  updateReply
}: Props) => {
  const [comment, setComment] = useState('');
  const [touched, setTouched] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [handleMutation, { loading }] = useMutation(CREATE_FEEDBACK_REPLY, {
    onCompleted: () => {
      setIsSubmitted(true);
      updateReply(index);
    }
  });
  const [handleAlert] = useHandleMutationAlert();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setComment(value);
  };

  const handleBlur = () => {
    if (!touched) setTouched(true);
  };

  const handleSubmit = async () => {
    const { data, errors } = await handleMutation({
      variables: {
        comment,
        id: feedbackId,
        origin
      }
    });

    handleAlert({
      errors,
      datadogRumMsg: 'Oneflare | Business Public Profile | CREATE_FEEDBACK_REPLY mutation',
      successMsg: 'Your reply has been successfully submitted'
    });

    return data;
  };

  return (
    <ReplyToReviewWrapperStyled>
      {isSubmitted ? (
        <LoaderWrapperStyled>
          <LoaderStyled />
        </LoaderWrapperStyled>
      ) : (
        <>
          <Anchor onClick={() => setIsExpanded(!isExpanded)}>Reply</Anchor>
          <CollapsibleContainer expanded={isExpanded}>
            <TextAreaStyled
              id="replyToReview"
              name="replyToReview"
              initialHeight={100}
              value={comment}
              error={(touched && !comment) ? 'Please enter a reply' : ''}
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ButtonStyled
              type="submit"
              kind="primary-sm"
              label="Send reply"
              onClick={handleSubmit}
              isLoading={loading}
              disabled={!comment}
            />
          </CollapsibleContainer>
        </>
      )}
    </ReplyToReviewWrapperStyled>
  );
};

export default memo(ReplyToReview);

import AsyncSVG from '@oneflare/flarekit/lib/components/AsyncSvg';
import dynamic from 'next/dynamic';

type ClientSideAsyncSvgProps = React.ComponentProps<typeof AsyncSVG>;

const ClientSideAsyncSvg = (props: ClientSideAsyncSvgProps) => {
  return <AsyncSVG {...props} />;
};

export default dynamic(() => Promise.resolve(ClientSideAsyncSvg), {
  ssr: false
});

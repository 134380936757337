import Head from 'next/head';
import { useContext, useState } from 'react';

import { JobFormControllerContext } from 'lib/oneflare-job-form/utils/context';
import { publicRuntimeConfig } from 'lib/utils/Environment';

import { oneflarePopularCategories } from './constants';
import * as Styled from './styled/Error404Styled';

const isNotOneflareBrand = publicRuntimeConfig.DOMAIN === 'wedding' || false;
const heroHeadingText = `Oops 404 error, ${isNotOneflareBrand ? ' we can\'t seem to find this page' : ' let\'s get this sorted.'}`;
const heroImageSrc = `https://cdn.oneflare.com/static/client/error/spanner-illustrations-${publicRuntimeConfig.DOMAIN}.svg`;

function Error404 () {
  const { controller } = useContext(JobFormControllerContext);
  const handleJobFormLaunch = () => {
    if (!controller) return;
    controller.open();
  };

  return (
    <>
      <Head>
        <title>Page not found - 404</title>
      </Head>
      <Styled.Header>
        <Styled.LogoLink href="/">
          <RenderLogo />
        </Styled.LogoLink>
      </Styled.Header>
      <Styled.HeroWrapper $hasBackgroundColor={!isNotOneflareBrand}>
        <Styled.Hero>
          <Styled.HeroContent>
            <Styled.HeroHeading>{heroHeadingText}</Styled.HeroHeading>
            <Styled.HeroParagraph>
              Looks like the page you were looking for no longer exists.
              Here are some helpful links to get you back on track.
            </Styled.HeroParagraph>
            <Styled.HeroButton link="/">Go to homepage</Styled.HeroButton>
            <Styled.PostJobButton kind="primary" label="Post a job" onClick={handleJobFormLaunch} />
          </Styled.HeroContent>
          <Styled.HeroImage
            src={heroImageSrc}
            loading="eager"
            alt="Two people holding a spanner"
          />
        </Styled.Hero>
      </Styled.HeroWrapper>
      <RenderPopularCategories />
    </>
  );
}

function RenderLogo () {
  switch (publicRuntimeConfig.DOMAIN) {
    case 'wedding':
      return <Styled.WeddingLogo />;
    default:
      return <Styled.CobrandingLogo />;
  }
}

function RenderPopularCategories () {
  const [showMoreCategories, setShowMoreCategories] = useState(false);
  const seeMoreButtonLabel = `See ${showMoreCategories ? 'less' : 'more'} categories`;
  const handleShowMoreCategories = () => {
    setShowMoreCategories((revealed) => !revealed);
  };

  if (isNotOneflareBrand) return null;

  return (
    <Styled.PopularCategories>
      <Styled.PopularCategoriesWrapper>
        <Styled.PopularCategoriesHeading>
          Browse popular categories
        </Styled.PopularCategoriesHeading>
        <Styled.PopularCategoriesList>
          <RenderInitialPopularCategories />
        </Styled.PopularCategoriesList>
        <RenderOtherPopularCategories showMoreCategories={showMoreCategories} />
        <Styled.SeeMoreButton
          kind="default-sm"
          data-testid="see-more-or-less-button"
          label={seeMoreButtonLabel}
          onClick={handleShowMoreCategories}
        />
      </Styled.PopularCategoriesWrapper>
    </Styled.PopularCategories>
  );
}

function RenderInitialPopularCategories() {
  return oneflarePopularCategories.slice(0, 4).map((category) => (
    <Styled.PopularCategory key={category.title} href={category.url} data-testid="popular-category">
      <Styled.CategoryImage $backgroundImage={category.image} />
      <Styled.CategoryTile>{category.title}</Styled.CategoryTile>
    </Styled.PopularCategory>
  ));
}

function RenderOtherPopularCategories ({ showMoreCategories = false}) {
  if (!showMoreCategories) return null;

  return (
    <>
    <Styled.OtherCategoriesTitle>Other popular categories</Styled.OtherCategoriesTitle>
    <Styled.OtherCategoriesList>
      {oneflarePopularCategories
        .slice(4, oneflarePopularCategories.length)
        .map((category) => (
          <Styled.OtherCategory key={category.title} data-testid="popular-category">
            <Styled.OtherCategoryLink href={category.url}>
              {category.title}
            </Styled.OtherCategoryLink>
          </Styled.OtherCategory>
        ))}
    </Styled.OtherCategoriesList>
  </>
  );
}

export default Error404;

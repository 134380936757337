import { memo } from 'react';

import FreeQuotesForm from 'shared/components/FreeQuotesForm';

import { GetFreeQuotesWrapperStyled } from './styled/BppFooter';

const BppFooter = ({ isStickyHeader = false }) => {
  return (
    <GetFreeQuotesWrapperStyled $isStickyHeader={isStickyHeader}>
      <FreeQuotesForm kind="narrow" />
    </GetFreeQuotesWrapperStyled>
  );
};

export default memo(BppFooter);

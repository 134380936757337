import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import Button from '@oneflare/flarekit/lib/components/Button';
import ButtonLink from '@oneflare/flarekit/lib/components/ButtonLink';
import H1 from '@oneflare/flarekit/lib/components/H1';
import H2 from '@oneflare/flarekit/lib/components/H2';
import H3 from '@oneflare/flarekit/lib/components/H3';
import CobrandingLogoComponent from '@oneflare/flarekit/lib/components/logos/Cobranding';
import WeddingLogoComponent from '@oneflare/flarekit/lib/components/logos/Wedding';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled, css } from 'styled-components';

export const Header = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.color.white};
  height: 72px;
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.color.cloud};
  display: flex;
  align-items: center;
  padding: 0 20px;

  ${media.lgUp`
    padding: 0 40px;
  `}
`;

export const HeroWrapper = styled.section<{
  $hasBackgroundColor: boolean;
}>`
  ${({ $hasBackgroundColor }) => $hasBackgroundColor && css`
    background-color: ${({ theme }) => theme.color.tin};
  `};

  width: 100%;
`;

export const Hero = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  max-width: 1120px;
  margin: auto;
  padding: 40px 20px;
  grid-template-columns: 1fr;
  gap: 20px;

  ${media.mdUp`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.lgUp`
    padding: 80px 40px;
  `}
`;

export const HeroContent = styled.div`
  width: 100%;

  ${media.lgUp`
    width: 536px;
  `}
`;

export const HeroHeading = styled(H1)`
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 28px;
  line-height: 1.5;
  width: 100%;
  color: ${({ theme }) => theme.color.charcoal};
  margin: 0 0 24px;

  ${media.lgUp`
    font-size: 40px;
 `}
`;

export const HeroParagraph = styled(Paragraph)`
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 40px;
  color: ${({ theme }) => theme.color.gunMetal};
`;

export const HeroButton = styled(ButtonLink)`
  width: 100%;

  &:first-of-type {
    margin: 0 16px 16px 0;
  }

  ${media.mdUp`
    width: 232px;
  `}
`;

export const PostJobButton = styled(Button)`
  width: 100%;
  margin: 0 16px 16px 0;

  &:first-of-type {
    margin: 0 0 16px;
  }

  ${media.mdUp`
    width: 232px;
  `}
`;

export const PopularCategories = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.color.white};
`;

export const PopularCategoriesWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1160px;
  margin: 0 auto;
  padding: 80px 0 0;
`;

export const PopularCategoriesHeading = styled(H2)`
  font-family: ${({ theme }) => theme.font.secondaryBold};
  color: ${({ theme }) => theme.color.charcoal};
  font-size: 22px;
  line-height: 1.5;
  margin: 0 20px 24px;

  ${media.lgUp`
    font-size: 28px;
     margin: 0 40px 24px;
  `}
`;

export const PopularCategoriesList = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 20px 20px;

  ${media.mdUp`
    grid-template-columns: repeat(4, 1fr);
  `}

  ${media.lgUp`
    margin: 0 40px 20px;
  `}
`;

export const PopularCategory = styled(Anchor)`
  margin: 0;

  &:hover {
    color: ${({ theme }) => theme.color.charcoal};
  }
`;

export const CategoryImage = styled.div<{
  $backgroundImage: string;
}>`
  width: 100%;
  height: 144px;
  margin: 0 0 8px;
  background-image: ${({ $backgroundImage }) => `url('${$backgroundImage}')`};
  background-size: cover;
  background-position: center;
  border-radius: 4px;

  ${media.lgUp`
    height: 180px;
  `}
`;

export const CategoryTile = styled(H3)`
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  color: ${({ theme }) => theme.color.charcoal};
  margin: 0;
`;

export const SeeMoreButton = styled(Button)`
  width: calc(100% - 40px);
  margin: 0 20px 20px;

  ${media.mdUp`
    width: 232px;

  `}

  ${media.lgUp`
   margin: 0 40px 40px 40px;

  `}
`;

export const HeroImage = styled.img`
  width: 100%;
  margin: 32px 0 0;

  ${media.mdUp`
    max-width: 404px;
    margin: 0 0 0 40px;
  `}
`;

export const WeddingLogo = styled(WeddingLogoComponent)`
  width: 200px;
  height: 32px;
  display: block;
`;

export const CobrandingLogo = styled(CobrandingLogoComponent)`
  width: 150px;
  height: 28px;
  display: block;
`;

export const OtherCategoriesList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-gap: 8px 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 20px 20px;

  ${media.mdUp`
    grid-template-columns: repeat(4, 1fr);
  `}

  ${media.lgUp`
    margin: 0 40px 20px;
  `}
`;

export const OtherCategory = styled.li`
  margin: 0;
`;

export const OtherCategoryLink = styled(Anchor)`
  font-size: 16px;
  line-height: 1.5;
`;

export const OtherCategoriesTitle = styled(H3)`
  margin: 0 20px 16px;
  width: 100%;
  line-height: 1.5;
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.secondaryBold};
  color: ${({ theme }) => theme.color.charcoal};

  ${media.lgUp`
    margin: 0 40px 16px;
  `}
`;

export const LogoLink = styled(Anchor)`
  margin: 0;
`;

import PropTypes from 'prop-types';
import { memo } from 'react';
import Lazy from '../Lazy';
import { LazyContainer } from './styled/LazyLoadedSection';

const propTypes = {
  autoLoad: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  minHeight: PropTypes.number,
  rootMargin: PropTypes.string,
  style: PropTypes.shape()
};

const defaultProps = {
  // Until we can verify that google is alright with our
  // dynamic rendering, don't lazy load by default.
  // Once this is done, we can use false as the default
  autoLoad: true,
  rootMargin: '300px',
  minHeight: 300,
  children: null,
  style: {}
};

const LazyLoadedSection = (props) => {
  const {
    children,
    autoLoad,
    rootMargin,
    minHeight,
    ...rest
  } = props;
  const observerOptions = { rootMargin };
  return (
    <Lazy
      autoLoad={autoLoad}
      observerOptions={observerOptions}
    >
      {(loaded) => {
        return (
          <LazyContainer {...rest} $minHeight={minHeight}>
            {loaded && children}
          </LazyContainer>
        );
      }}
    </Lazy>
  );
};

LazyLoadedSection.propTypes = propTypes;
LazyLoadedSection.defaultProps = defaultProps;

export default memo(LazyLoadedSection);

import { useLazyQuery } from '@apollo/client';
import { memo, useCallback, useContext, useState } from 'react';

import { JobFormControllerContext } from 'lib/oneflare-job-form/utils/context';
import { AUTOCOMPLETE_CATEGORY } from 'queries/oneflare.com.au/autocompleteCategory';
import { AUTOCOMPLETE_LOCATION } from 'queries/shared/autocompleteLocation';

import * as Styled from './styled/FreeQuotesForm';

type FreeQuotesFormProps = {
  kind?: Styled.FreeQuotesFormKind;
};

function  FreeQuotesForm({ kind = 'default' }: FreeQuotesFormProps) {
  const [locationData, setLocationData] = useState({ id: null, value: '' });
  const [categoryData, setCategoryData] = useState({ id: null, name: '' });
  const [autocompleteCategory, setAutocompleteCategory] = useState([]);
  const [autocompleteLocation, setAutocompleteLocation] = useState([]);

  const { controller } = useContext(JobFormControllerContext);

  const [refetchCategory] = useLazyQuery(AUTOCOMPLETE_CATEGORY, {
    fetchPolicy: 'cache-and-network'
  });

  const [refetchLocations] = useLazyQuery(AUTOCOMPLETE_LOCATION, {
    fetchPolicy: 'cache-and-network'
  });

  const handleJobFormLaunch = useCallback(async () => {
    await controller.open({
      categoryId: categoryData.id,
      locationId: locationData?.id,
      locationName: locationData?.value
    });
  }, [categoryData.id, controller, locationData?.id, locationData?.value]);

  const handleCategoryChange = async ({ target: { value } }) => {
    if (value === categoryData.name) return;

    setCategoryData({ id: null, name: value });

    if (value && refetchCategory) {
      const { data } = await refetchCategory({
        variables: { term: value }
      });
      setAutocompleteCategory(data?.autocompleteCategory.map((item) => ({
        ...item, value: item.name
      })) || []);
    }
  };

  const handleLocationChange = async ({ target: { value } }) => {
    if (value === locationData.value) return;

    setLocationData({ id: null, value });

    if (value && refetchLocations) {
      const { data } = await refetchLocations({
        variables: { term: value }
      });
      setAutocompleteLocation(data?.autocompleteLocation.map((item) => ({
        ...item, name: item.value
      })) || []);
    }
  };

  return (
    <Styled.GetFreeQuotesForm $kind={kind}>
      <FreeQuotesFormHead kind={kind} />
      <Styled.ServiceInput
        id="category-autocomplete"
        itemsHaveBeenPreFiltered
        renderSubList={(item) => item.serviceTypes.slice(0, 4).join(', ')}
        label="What service do you need?"
        placeholder="eg. Plumber, Cleaner, DJ etc"
        onChange={handleCategoryChange}
        onSelect={(value) => setCategoryData(value)}
        value={categoryData.name}
        items={autocompleteCategory}
        $kind={kind}
      />
      <Styled.LocationInput
        id="location-autocomplete"
        label="Where do you need it?"
        placeholder="Postcode"
        onChange={handleLocationChange}
        onSelect={(value) => setLocationData(value)}
        value={locationData.value}
        items={autocompleteLocation}
        $kind={kind}
      />
      <Styled.GetFreeQuotesButton
        label="Get free quotes"
        kind="primary"
        onClick={handleJobFormLaunch}
        $kind={kind}
      />
    </Styled.GetFreeQuotesForm>
  );
}

function FreeQuotesFormHead({ kind }: {kind: Styled.FreeQuotesFormKind }) {
  if (kind === 'narrow') {
    return (
      <>
        <Styled.GetFreeQuotesDowngradedTitle>Need an expert?</Styled.GetFreeQuotesDowngradedTitle>
        <Styled.SubTitle>Tell us about your job and get free quotes.</Styled.SubTitle>
      </>
    );
  }
  return (
    <Styled.GetFreeQuotesTitle>Where to-do gets done</Styled.GetFreeQuotesTitle>
  );
}

export default memo(FreeQuotesForm);

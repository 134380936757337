import { gql } from '@apollo/client';

export const AUTOCOMPLETE_CATEGORY = gql`
  query AutocompleteCategory($term: String!) {
    autocompleteCategory(term: $term) {
      id
      name
      serviceTypes
      suggestion
    }
  }
`;

export type AutoCompleteCategoryResponse = {
  autocompleteCategory: Array<{
    id: string;
    name: string;
    serviceTypes: Array<string>;
    suggestion: boolean;
  }>;
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AsyncSVG from 'shared/components/ClientSideAsyncSvg';
import { uuidv4 } from 'shared/utils/helpers';

import { StarRatingStyled } from './styled/StarRating';
import { starQuarters } from './utils';

class StarRating extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    fillBackground: PropTypes.string,
    fillStar: PropTypes.string,
    rating: PropTypes.number.isRequired
  };

  static defaultProps = {
    className: '',
    fillBackground: null,
    fillStar: null
  };

  render() {
    const {
      className,
      fillBackground,
      fillStar,
      rating
    } = this.props;

    return (
      <StarRatingStyled
        className={className}
        $fillStar={fillStar}
        $fillBackground={fillBackground}
      >
        {starQuarters(rating).map((quarters) => (
          <AsyncSVG
            className="star"
            key={uuidv4()}
            path={`https://cdn.oneflare.com/static/icons/star${quarters}.svg`}
          />
        ))}
      </StarRatingStyled>
    );
  }
}

export default StarRating;
